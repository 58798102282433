import Typography from "typography"
import usWeb from 'typography-theme-us-web-design-standards'
import { theme } from './colors.js'

usWeb.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

usWeb.googleFonts = [
    {
        name: "M PLUS 1p",
        styles:[ "400", "700" ]
    },
    {
        name: "Source Sans Pro",
        styles:[ "400", "400i", "700" ]
    }
]

usWeb.headerFontFamily = [
    "M PLUS 1p",
    "monospace"
]

usWeb.baseFontSize = '20px'

usWeb.overrideThemeStyles = () => ({
    'body': {
        color: theme.text,
        backgroundColor: theme.background
    },
    '::selection': {
        backgroundColor: theme.selection
    },
    'pre,code': {
        backgroundColor: theme.codeBackground,
        overflowX: 'auto',
        boxSizing: 'border-box',
        padding: '0 2px' 
    },
    'a, a:visited': {
        color: theme.link
    }
})

const typography = new Typography(usWeb)

// Hot reload typography in development.
/*if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}*/

//export default typography
//export const rhythm = typography.rhythm
//export const scale = typography.scale
