import React from "react"
import { Link } from "gatsby"
import Card from "../components/card"
import svg from "../css/svg/kei_logo.svg"

import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
    <div>
      <div class="flex">
        <header>
            <h1 id="top"><span>ref <code id="k-item" className="language-text">k</code></span>
            <span className="right">
                <input id="autocomplete" placeholder="Search..." />
                <img src={svg} className="avatar" />
            </span>
            </h1>
            <Card content={this.props.card} />
        </header>
        <main>{children}</main>
      <footer>
          © {new Date().getFullYear()}, Developed by
          {` `}
          <a href="https://github.com/kparc" title="friends of k">us</a>
      </footer>
      </div>
      </div>
    )
  }
}

export default Layout
