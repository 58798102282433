import cheerio from "cheerio"
import _ from "lodash"

import text from './card.txt'

//const r = /[^a-zA-Z0-9_ /@$'`\-+]| {2,}/
const r = / /

const e = i => i ? -i : ''

export default function postproces(html) {
    let $ = cheerio.load(html)

    let a = text.split('\n').map(s => s.trimRight()).join('\n')
    /*
    let ops = _($('h2,h3').toArray())
        .each(el => {
            let code = $(el).find('code').text().trim()
            if (!code)
                return;
            let id = _.kebabCase($(el).text())
            if (!$(`#${id}`).length) {
                if (/^\w+$/.test(code)) {
                    a = a.replace(code, `<a href="#${id}">${code}</a>`)
                } else {
                    console.log(a.split('  '))
                }
            }
        })
    */
    let h2s = _($('h2,h3').toArray())
        .groupBy(el => _.kebabCase($(el).text().split(r)[0].trim()))
        .forOwn((v, k) =>
            v.forEach((el, i) => {
                if (!k)
                    return;
                $(el).attr('id', `${k}${e(i)}`)
                //i || console.log(k)
                //!i || 
                //    $(el).after(`<a href="#${k}${e(i-1)}">prev</a>`)
                //i === v.length - 1 || 
                //    $(el).after(`<a href="#${k}-${i+1}">next</a>`)
            })
        )
    
    let keywords = text.split(r).map(s => ({ o: s, tok: _.kebabCase(s.trim())})).filter(s => s.tok.length > 1)
    _(keywords)
        .uniq(w => w.tok)
        .forEach(w => {
            if(_.keys(h2s).includes(w.tok)) {
                a = a.replace(` ${w.o} `, ` <a href="#${w.tok}">${w.o}</a> `)
            }
        })
        

    let pre = $('pre.language-q code')
    pre.each((i, p) => $(p).replaceWith($(p).html()))
    pre = $('pre.language-q')
    pre.each((i, p) => {
        $(p).replaceWith(`<pre class="language-q">${$(p)
            .html()
            .split('\n')
            .map(t => t[0] === ' '
                ? `<code class="language-q in">${t.trimLeft()}</code>`
                : `<code class="language-q out">${t}</code>`)
            .join('\n')
            }</pre>`)
    })

    $('table').each((i, el) => $(el).replaceWith(`<div style="width: 100%; overflow-x: auto">${$.html($(el))}</div>`))
    return ({ text: a, html: $.html() })
}
