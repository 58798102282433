import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

class Card extends React.Component {
    render() {
        return (
            <code className="refcard language-q" dangerouslySetInnerHTML={{ __html: this.props.content }}>
            </code>
        )
    }
}

export default Card
