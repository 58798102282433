import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import postprocess from "../utils/postprocess"

import css from "../css/all.css"


class Index extends React.Component {
  render() {
    const { data } = this.props
    const post = data.markdownRemark
    const siteTitle = data.site.siteMetadata.title
    const { html, text } = postprocess(post.html)

    return (<div>
      <Layout location={this.props.location} title={siteTitle} card={text}>
        <SEO title={siteTitle} />
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Layout>
    </div>)
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/ref/" } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
      }
    }
    file(base: {eq: "card.txt"}) {
      id
      internal {
        content
      }
    }
  }
`
