const colors = {
    ghostWhite: '#F8F8F0',
    lightGhostWhite: '#F8F8F2',
    lightGray: '#CCC',
    gray: '#888',
    brownGray: '#49483E',
    darkGray: '#282828',

    yellow: '#E6DB74',
    blue: '#66D9EF',
    pink: '#F92672',
    purple: '#AE81FF',
    brown: '#75715E',
    orange: '#FD971F',
    lightOrange: '#FFD569',
    green: '#A6E22E',
    seaGreen: '#529B2F',
}

const dark = {
    text: colors.lightGhostWhite,
    cursor: colors.ghostWhite,
    selection: colors.brownGray,
    background: colors.darkGray,
    codeBackground: '#4C4745',
    link: colors.pink
}

export default colors
export const theme = dark
